import { Cookies } from '../adapter/cookies'
import { CmpImpl } from './cmp.impl'
import { loggingService } from '../utils/logger'
import { initGlobal } from './bootstrap/init-global'
import { initNexus } from './bootstrap/init-nexus'
import { ConsentProviderAdapter } from '../adapter/consent-provider.adapter'
import { ReceiptArchiver } from './receipt-archiver'
import { cookie } from '@otto-ec/global-resources/cookie'
import { AppsAdapter } from '../adapter/apps.adapter'
import { Cmp } from './cmp.type'
import { toggle } from '@otto-ec/global-resources/toggle'
import { LoggingService } from '../utils/logging-service'
import { CmpTcfApi } from '../adapter/tcf-api/tcf-api.type'
import { ConsentConfigLoader } from './consent-config/consent-config-loader'
import { ConsentConfigService } from './consent-config/consent-config.service'

const GVL_PATH = import.meta.env.BASE_URL

init().catch((e) =>
  loggingService.logError(new Error('Error initializing cmp', { cause: e }))
)

async function init() {
  const appsAdapter = new AppsAdapter()

  const consentProviderAdapter = new ConsentProviderAdapter(appsAdapter)

  const receiptArchiver = new ReceiptArchiver(loggingService)

  const cookies = new Cookies(cookie)

  const consentConfigService = new ConsentConfigService(
    new ConsentConfigLoader()
  )
  const cmp: Cmp = new CmpImpl(
    consentProviderAdapter,
    await initTcfApi(loggingService),
    receiptArchiver,
    cookies,
    loggingService,
    consentConfigService
  )
  initNexus(cmp)
  initGlobal(cmp)
}
async function initTcfApi(loggingService: LoggingService): Promise<CmpTcfApi> {
  if (toggle.get('cmp_optimize_cmp_api_load', false)) {
    return new (
      await import('./iab-tcf/tcf-no-vendor-list.service')
    ).TcfNoVendorListService(loggingService)
  }
  return new (
    await import('./iab-tcf/cmp-iab-tcf-api.service')
  ).CmpIabTcfApiService(GVL_PATH, loggingService)
}
